export default {
  login: 'user/signin',
  revoke: 'user/signout',
  user_info: 'user/info',
  search_customer: 'user/search-customer',
  customer_info: 'user/customer-info',
  deactive_customer: 'user/deactive-customer',
  active_customer: 'user/active-customer',
  get_shops: 'user/get-shops',
  address_get_all: 'user/address/get-all',
  address_get_default: 'user/address/get-default',
  address_create: 'user/address/create',
  address_update: 'user/address/update',

  search_employee: 'user/search-employee',
  employee_create: 'user/create-employee',
  employee_update: 'user/update-employee',
  employee_delete: 'user/delete-employee',
  employee_get_info: 'user/employee-info',
  update_shop_price_sale: 'user/update-shop/prices-sale',

  forgot_password: 'user/forgot-password/otp-send',
  forgot_password_verify_otp: 'user/forgot-password/verity-otp',
  forgot_password_update: 'user/forgot-password/update',

  change_password_check: 'user/change-password/check-password',
  change_password_update: 'user/change-password/update-password',

  get_partners: 'common/logistic-partner/all',
  get_delivery_notes: 'common/role/get-delivery-notes',
  get_all_order_type: 'common/get-all-order-type',

  admin_get_prices: 'prices/admin-prices/get-prices',
  admin_prices_create: 'prices/admin-prices/create',
  admin_prices_update: 'prices/admin-prices/update',

  shop_get_prices: 'prices/customer-prices/get-shop-prices-group',
  shop_prices_create: 'prices/customer-prices/create-shop-prices',
  shop_prices_update: 'prices/customer-prices/update-shop-prices',
  get_prices_group: 'prices/customer-prices/get-prices-group',

  customer_get_prices: 'prices/customer-prices/get-prices',
  customer_prices_create: 'prices/customer-prices/create-personal-prices',
  customer_prices_update: 'prices/customer-prices/update-personal-prices',
  customer_get_detail_price: 'prices/customer-prices/prices-group/detail',

  province_all: 'province/findAll',
  find_all_districts: 'district/findAllDistricts',
  find_all_communes: 'commune/findAllCommunes',

  get_sales: 'common/partner/get-accounts',
  create_account: 'common/partner/create-account',
  update_sale_ninjavan: 'common/partner/update-account',
  get_roles: 'common/role/get-roles',
  active_sale_ninjavan: 'common/partner/update-account/active',
  deactive_sale_ninjavan: 'common/partner/update-account/deactive',
  delete_sale_ninjavan: 'common/partner/update-account/delete',
  services_all: 'common/services/all',
  get_detail_price_group: 'prices/admin-prices/prices-group/detail',

  create_orders: 'orders/create',
  calculate_ship_fee: 'orders/calculate-all-ship-fee-partners',
  multi_calculate_ship_fee: 'orders/multiple-packages/calculate-all-ship-fee-partners',
  calculate_service_fee: 'common/cal-service-fee',
  search_orders: 'orders/search-orders',
  orders_status: 'orders/get-all-status',
  orders_detail: 'orders/orders-detail',
  orders_cancel: 'orders/cancel-orders',
  orders_update: 'orders/update-order',
  edit_orders: 'orders/edit-order',
  get_last_transport_type: 'orders/getLastTransportType',
  get_receiver_info_by_phone: 'orders/getReceiverInfoByPhone',
  get_establish_service_by_partner: 'common/feetag/commodity/findByPartnerId',
  get_find_for_user_Id: 'common/services/findForUserId',

  create_multiple_orders: 'orders/create-multi-orders',
  orders_view_from_excel: 'orders/view-from-excel',
  orders_download_template: 'orders/download-order-template',

  way_bill: 'report/way-bill',
  report_files: 'report/files',

  get_all_district: 'district/findAll',
  get_all_commune: 'commune/findAll',
  get_logistic_partner: 'common/get-services-partners',
  get_admin_prices_group: 'prices/admin-prices/get-admin-prices-group',

  get_operator_postoffice: 'common/postoffice/get-operator-postoffice',
  search_employee_postoffice: 'user/search-employee',
  get_shops_of_sale: 'user/get-shops-of-sale',
  tunr_off: 'user/shop/turn-off',
  get_all_pick_work_ship: 'common/get-all-pick-work-ship',
  get_post_office: 'post-office/search',
  create_post_office: 'post-office/create',
  detail_post_office: 'post-office/detail',
  edit_post_office: 'post-office/edit',
  po_price: 'prices/search-po-prices',
  po_create_price: 'prices/po-prices/create',
  po_detail_price: 'prices/detail-po-prices',
  po_update_price: 'prices/po-prices/update',

  create_shop: 'user/create-shop',
  search_manage: 'user/search-manage',
  active_manage: 'user/active-manage',
  inactive_manage: 'user/inactive-manage',
  create_manage: 'user/create-manage-po',
  get_account_not_assign: 'common/partner/get-accounts-not-assign',
  create_multi_packages: 'orders/create-multi-packages',

  statis_amount: 'orders/statistic/cust/amount',
  statis_revenue: 'orders/statistic/cust/revenue'
}
