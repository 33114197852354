export default {
  TOKEN: 'TOKEN',
  TOKEN_EXP: 'TOKEN_EXP',
  CURRENT_USER: 'CURRENT_USER',
  USER_NAME: 'USER_NAME',
  TIME_GET_OTP: 'TIME_GET_OTP',
  LANGUAGE: 'LANGUAGE',
  PROVINCES: 'PROVINCES',
  DISTRICTS: 'DISTRICTS',
  COMMUNES: 'COMMUNES',
  typeUrl: {
    passport: 0
  },
  typeAlert: {
    success: 'success',
    error: 'error',
    warning: 'warning'
  },
  statusCode: {
    ok: 200,
    badRequest: 400
  },
  widthDialog: 360,
  regExp: {
    email:
      '^[a-zA-Z]+([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+([.]?[a-zA-Z0-9]+)*(.[a-zA-Z]{2,3})+$',
    phone: '^(0)(3|5|7|8|9)[0-9]{8}$',
    password: '(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[`~!@#$%^*()_|+=?;:".<>\'{}[\\/])',
    waybill: '^[a-zA-Z0-9]+$',
    number: '^[0-9]+$',
    numberFm: '^[0-9,]+$',
    decimal: '^[0-9.,]+$',
    not_zero: '^(?!0$).*',
    tracking_id: '^[0-9A-Za-z]{6,18}$'
  },
  basicAuth: {
    user: 'web',
    pass: 'web',
    grantType: 'password'
  },
  roleRoot: 'ROLE_ROOT',
  roleAdmin: 'ROLE_ADMIN',
  roleSale: 'ROLE_SALE',
  roleCustomerCare: "ROLE_CUSTOMER_CARE",
  roleAccountant: "ROLE_ACCOUNTANT",
  roleAccept: ['ROLE_ROOT', 'ROLE_ADMIN', 'ROLE_SALE', 'ROLE_CUSTOMER_CARE', 'ROLE_ACCOUNTANT'],
  separation: '.',
  signFormatMoney: ',',
  maximumAfterDecimal: 2,
  undefined: 'undefined',

  minimumEmail: 3,
  maximumEmail: 70,
  minimumPhone: 10,
  maximumPhone: 10,
  minimumPassword: 8,
  maximumPassword: 32,
  maximumOrderWeight: 6,
  maximumWeight: 10,
  maximumPrice: 12,
  maximumPriceFm: 15,
  maximumSmallText: 100,
  maximumLargeText: 1000,
  maximumOtp: 6,
  minimumWaybill: 6,
  maximumWaybill: 18,
  maximumSizeLwHFm: 5,
  maximumRecordExport: 1000,
  maximumMoneyCod: 10,
  maximumPriceValueFm: 10,
  maximumOrderValue: 20000000,
  maximumCodValue: 20000000,
  maximumLimitPerPage: 9999,
  maximumInsurance: 1000000,

  formatDate: 'DD/MM/YYYY',
  formatDateDmy: 'yyyy/MM/DD',
  formatDateYmd: 'YYYY-MM-DD',
  formatDateTime: 'dd/MM/yyyy HH:mm:ss',
  formatDateTimeHm: 'DD/MM/yyyy HH:mm',
  formatDatePlaceholder: 'DD/MM/YYYY',
  formatTimeHm: 'HH:mm',
  formatDateTimeInt: 'ddMMyyyyHHmmss',
  formatDateTimeCompare: 'yyyyMMddHHmmss',

  timeOut: 3000,
  timeOutRole: 2500,
  initTimeCountDown: 60,
  startsWithOtpOverLimit: '3,2',
  limitHourOrderCreate: 16,
  numberDateAllowedOrderCreate: 2,

  createPriceListType: {
    partner: 'PARTNER',
    shop: 'SHOP',
    guest: 'GUEST'
  },
  customerType: {
    shop: 'SHOP',
    personal: 'PERSONAL'
  },
  transportType: {
    ship: 'SHIP',
    individual: 'INDIVIDUAL'
  },
  serviceType: {
    normal: 1,
    express: 2
  },
  pricesType: {
    standard: 'STANDARD',
    weight: 'WEIGHT'
  },
  status: {
    del: 4
  },
  priceTypeTabs: {
    standard: 0,
    weight: 1
  },
  itemsPerPage: 20,
  pageSizeMax: 100,
  pageSize: 10,
  numberEmployeesPerPage: 10,
  customerStatus: {
    activated: 1,
    waitingActivation: 0
  },
  fixDefaulValue: -99,
  serviceFeePayers: {
    sender: 'SENDER',
    receiver: 'RECEIVER'
  },
  orderInsurance: {
    yes: 1,
    no: 0
  },
  orderStatus: {
    cancelled: -1,
    waiting: 0,
    delivering: 1,
    successfulDelivery: 2,
    completed: 3,
    waitingDelivery: 4,
    pickUpFailed: 5,
    refundingToSender: 6,
    refundedToSender: 7,
    parcelWeight: 8,
    orderReweighed: 9, // đơn hàng được cân lại
    failDelivery: 10 // cho xu ly
  },
  statusPartnerAccount: {
    active: 1,
    inactive: 0
  },
  waybillPrefix: 'SVC',
  errorValidation: 'common.message.error.validation',
  typeExport: {
    excel: 'xlsx',
    csv: 'csv'
  },
  servicesShipping: {
    normal: 1,
    express: 2,
    standard: 3,
    over: 4
  },
  partnerGroup: {
    ninjaVan: 1,
    ghtk: 2
  },
  errorMessage: {
    linkExist: 'Tài khoản liên kết đã được chọn !'
  },
  statusCustomer: {
    inactive: 0, // khong hoat dong
    active: 1, // dang hoat dong
    off: 5, // Ngung hoat dong
    delete: 4, // ngung hoat dong
  },
  feeTag: {
    insurance: 5, // bao hiem
    reinforced: 6, // gia co hang hoa
    fragile: 4 // hang de vo
  },
  feeTagUnitType: {
    percent: 0, // tinhs theo %
    volume: 1 // tinh theo cong don
  },
  weight: 20, // khoi luon ghtk
  statusCreateOrder: {
    success: 1,
    fail: -1,
    edited: 2 // da chinh don khong hop le khi tao
  },
  pickWorkShip: {
    morning: 1, // sang
    afternoon: 2, // chieu
    night: 3 // toi
  },
  statusAdmin: {
    active: 1, // kich hoat
    inactive: 0 // tat kich hoat
  },
  postOffice: {
    primary: 2, // PO 1
  },
  statusPo: {
    public: 1, // cong khai
    private: 0 // rieng tu
  }
}
